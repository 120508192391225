var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    [
      _c(
        "RatioContainer",
        { staticClass: "pick-team-player", class: _vm.playerClasses },
        [
          _c(
            "v-flex",
            {
              staticClass: "video-container outline",
              attrs: { "d-flex": "" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.edit($event)
                }
              }
            },
            [
              _c("UserMedia", {
                attrs: { identity: _vm.player.id, imageUrl: _vm.imageURL }
              }),
              _c(
                "div",
                {
                  staticClass: "player-details",
                  class: { "show-mute": _vm.mutable }
                },
                [
                  _c(
                    "div",
                    { staticClass: "player-name" },
                    [
                      _c("UserName", {
                        attrs: {
                          firstname: _vm.firstname,
                          lastname: _vm.lastname
                        }
                      })
                    ],
                    1
                  ),
                  _vm.mutable
                    ? _c(
                        "v-icon",
                        {
                          staticClass: "icon mute-btn",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.toggleAudio($event)
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.muted ? "volume_off" : "volume_up") +
                              " "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.player
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "400px" },
              model: {
                value: _vm.editPlayer,
                callback: function($$v) {
                  _vm.editPlayer = $$v
                },
                expression: "editPlayer"
              }
            },
            [
              _c(
                "v-layout",
                { attrs: { "justify-center": "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c(
                        "v-toolbar",
                        {
                          attrs: { color: "indigo", dark: "", height: "50px" }
                        },
                        [
                          _c("v-toolbar-title", [
                            _vm._v("Edit " + _vm._s(_vm.player.firstname))
                          ]),
                          _c("v-spacer")
                        ],
                        1
                      ),
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-container",
                            { attrs: { fluid: "", "grid-list-md": "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { row: "", wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    [
                                      _c(
                                        "form",
                                        {
                                          on: {
                                            submit: function($event) {
                                              $event.preventDefault()
                                              return _vm.onSignin($event)
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-card-text",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  name: "firstname",
                                                  label: "First Name"
                                                },
                                                model: {
                                                  value: _vm.player.firstname,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.player,
                                                      "firstname",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "player.firstname"
                                                }
                                              }),
                                              _c("v-text-field", {
                                                attrs: {
                                                  name: "lastname",
                                                  label: "Last Name"
                                                },
                                                model: {
                                                  value: _vm.player.lastname,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.player,
                                                      "lastname",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "player.lastname"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-card-actions",
                                        [
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            {
                                              on: {
                                                click: function($event) {
                                                  _vm.editPlayer = false
                                                }
                                              }
                                            },
                                            [_vm._v(" Cancel ")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "primary",
                                              on: {
                                                click: function($event) {
                                                  return _vm.savePlayer()
                                                }
                                              }
                                            },
                                            [_vm._v(" Save ")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { icon: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.deletePlayer()
                                                }
                                              }
                                            },
                                            [_c("v-icon", [_vm._v("delete")])],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }