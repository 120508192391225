<template>
  <v-flex>
    <RatioContainer :class="playerClasses" class="pick-team-player">
      <v-flex d-flex class="video-container outline" @click.stop="edit">
        <!-- Player video -->
        <UserMedia :identity="player.id" :imageUrl="imageURL" />
        <div class="player-details" :class="{ 'show-mute': mutable }">
          <div class="player-name">
            <UserName :firstname="firstname" :lastname="lastname" />
          </div>
          <v-icon
            class="icon mute-btn"
            v-if="mutable"
            @click.stop="toggleAudio"
          >
            {{ muted ? "volume_off" : "volume_up" }}
          </v-icon>
        </div>
      </v-flex>
    </RatioContainer>
    <v-dialog v-if="player" v-model="editPlayer" max-width="400px">
      <v-layout justify-center>
        <v-flex xs12>
          <v-toolbar color="indigo" dark height="50px">
            <v-toolbar-title>Edit {{ player.firstname }}</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card>
            <v-container fluid grid-list-md>
              <v-layout row wrap>
                <v-flex>
                  <form @submit.prevent="onSignin">
                    <v-card-text>
                      <v-text-field
                        v-model="player.firstname"
                        name="firstname"
                        label="First Name"
                      />
                      <v-text-field
                        v-model="player.lastname"
                        name="lastname"
                        label="Last Name"
                      />
                    </v-card-text>
                  </form>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn @click="editPlayer = false"> Cancel </v-btn>
                    <v-btn class="primary" @click="savePlayer()"> Save </v-btn>
                    <v-btn icon @click="deletePlayer()">
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>
        </v-flex>
      </v-layout>
    </v-dialog>
  </v-flex>
</template>

<script>
import RatioContainer from "@/components/GroupTeams/Common/RatioContaier.vue"
import UserName from "@/components/GroupTeams/Common/User/UserName"
import UserMedia from "@/components/GroupTeams/Common/User/UserMedia"
import { mapActions } from "vuex"

export default {
  name: "PickTeamPlayer",
  components: {
    RatioContainer,
    UserName,
    UserMedia
  },
  data() {
    return {
      isMediaReady: false,
      editPlayer: false,
      thePlayer: {}
    }
  },
  props: {
    imageURL: String,
    src: MediaStream,
    firstname: String,
    lastname: String,
    isHandRaised: Boolean,
    isSelected: Boolean,
    player: Object,
    muted: Boolean
  },
  computed: {
    mutable() {
      return this.player.id === this.user.id || this.user.role === "facilitator"
    },
    playerClasses() {
      const { isHandRaised, isSelected, mutable, muted } = this
      return {
        "move-player-hand": isHandRaised && !isSelected,
        "move-selected": isSelected,
        mutable: mutable,
        muted: muted
      }
    },
    user() {
      return this.$store.state.auth.user
    }
  },
  methods: {
    ...mapActions(["updateUser"]),
    toggleAudio() {
      return this.updateUser({
        userID: this.player.id,
        obj: { muted: !this.player.muted }
      })
    },
    edit(player) {
      if (this.user.role != "facilitator") return null
      this.thePlayer = player
      this.editPlayer = true
    },
    async savePlayer() {
      await this.$store.dispatch("updateUser", {
        userID: this.player.id,
        obj: { ...this.player, correct: null }
      })
      this.editPlayer = false
    },
    async deletePlayer() {
      await this.$store.dispatch("deletePlayer", { userID: this.player.id })
      this.editPlayer = false
    }
  }
}
</script>

<style lang="scss">
.pick-team-player {
  .video-container {
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border: 2px solid $color-white;
    background-color: $color-white;
    box-shadow: 0 0 4px rgba($color-black, 0.5);
  }

  .player-name {
    font-size: 11px;
  }

  .player-details {
    position: absolute;
    background-color: rgba($color-black, 0.5);
    bottom: 0;
    left: 0;
    width: 100%;
    padding-bottom: 6px;
    transition: padding-bottom 0.33s;
    &.show-mute {
      .mute-btn {
        position: absolute;
        cursor: pointer;
        left: 50%;
        margin-left: -8px;
        font-size: 15px;
        opacity: 0;
        transition: opacity 0.25s;
        color: $color-white !important;
      }
    }
  }

  &.mutable {
    &.muted,
    &:hover {
      .player-details {
        padding-bottom: 18px;
        &.show-mute {
          .mute-btn {
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>
