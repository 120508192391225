var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "pick-team",
      attrs: { "pa-0": "", fluid: "", "fill-height": "" }
    },
    [
      _c(
        "v-layout",
        { attrs: { column: "", "fill-height": "" } },
        [
          _c(
            "v-layout",
            {
              attrs: {
                "align-center": "",
                shrink: "",
                "justify-center": "",
                row: ""
              }
            },
            [
              _c(
                "Header",
                [
                  _c("v-spacer"),
                  _c(
                    "v-flex",
                    { staticClass: "sfx-volume-wrap" },
                    [_c("QuickVolumeTester", { staticClass: "volume-slider" })],
                    1
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-flex",
                                {
                                  staticClass: "logout-btn-wrap",
                                  attrs: { "pr-1": "" },
                                  on: { click: _vm.pushToPreGame }
                                },
                                [
                                  _c(
                                    "div",
                                    _vm._g({ staticClass: "logout-btn" }, on),
                                    [_vm._v("GAMES")]
                                  )
                                ]
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_c("span", [_vm._v("Return to the EXPO")])]
                  ),
                  !_vm.isHost
                    ? _c(
                        "v-flex",
                        {
                          staticClass: "logout-btn-wrap",
                          attrs: { "pl-0": "" },
                          on: { click: _vm.pushToLogout }
                        },
                        [
                          _c("div", { staticClass: "logout-btn" }, [
                            _vm._v("LOG OUT")
                          ])
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-layout",
            { attrs: { row: "", grow: "" } },
            [
              _c(
                "v-flex",
                { staticClass: "pick-team-left-col", attrs: { xs6: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { column: "", "fill-height": "" } },
                    [
                      _c(
                        "v-flex",
                        { staticClass: "top-left-cell", attrs: { shrink: "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", "fill-height": "" } },
                            [
                              _c(
                                "v-flex",
                                { staticClass: "host-media-container" },
                                [
                                  _c(
                                    "transition",
                                    {
                                      attrs: {
                                        name: "host-transition",
                                        mode: "out-in"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-flex",
                                        { staticClass: "host-media-wrap" },
                                        [_c("HostMedia")],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { staticClass: "pick-team-info" },
                                [
                                  _c(
                                    "v-flex",
                                    [
                                      !_vm.game.pickTeamLogo
                                        ? _c("h1", [
                                            _c("br"),
                                            _vm._v(
                                              _vm._s(
                                                _vm.game.externalName ||
                                                  _vm.game.name
                                              ) + " "
                                            ),
                                            _c("br"),
                                            _c("span", [_vm._v("hosted by")]),
                                            _vm._v(
                                              " " + _vm._s(_vm.hostName) + " "
                                            )
                                          ])
                                        : _vm._e(),
                                      !!_vm.startTime && _vm.isStandard
                                        ? _c(
                                            "v-flex",
                                            { staticClass: "started" },
                                            [
                                              _vm.time > 0
                                                ? _c("span", [
                                                    _vm._v(" Starts at ")
                                                  ])
                                                : _c("span", [
                                                    _vm._v(" Started at ")
                                                  ]),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "actual-start-time"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.startTime) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _c("hr"),
                                      !_vm.game.pickTeamText
                                        ? _c(
                                            "p",
                                            { staticClass: "body-text" },
                                            [
                                              _vm._v(
                                                " Please drag your icon to one of the teams to the right! "
                                              )
                                            ]
                                          )
                                        : _c("p", [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.game.pickTeamText) +
                                                " "
                                            )
                                          ]),
                                      _vm.game.pickTeamLogo
                                        ? _c(
                                            "p",
                                            {
                                              staticClass: "body-text",
                                              staticStyle: {
                                                "text-align": "center"
                                              }
                                            },
                                            [
                                              _c("img", {
                                                style:
                                                  _vm.game.pickTeamLogoStyle,
                                                attrs: {
                                                  src: _vm.game.pickTeamLogo
                                                }
                                              })
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "num-of-players-decorated-wrap"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "num-of-players-decorated"
                                        },
                                        [
                                          _c("SvgTriangle", {
                                            staticClass: "team-shape-left"
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "num-of-players" },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.numOfPlayersText)
                                                )
                                              ])
                                            ]
                                          ),
                                          _c("SvgTriangle", {
                                            staticClass: "team-shape-right"
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        {
                          staticClass: "all-players",
                          class: { gameimage: _vm.game.image },
                          style: _vm.backgroundStyle,
                          attrs: { grow: "" }
                        },
                        _vm._l(_vm.noTeam, function(item) {
                          return _c(
                            "v-flex",
                            {
                              key: item.id,
                              staticClass: "all-players-scroll",
                              attrs: { "no-action": "" },
                              model: {
                                value: item.active,
                                callback: function($$v) {
                                  _vm.$set(item, "active", $$v)
                                },
                                expression: "item.active"
                              }
                            },
                            [
                              _c(
                                "draggable",
                                _vm._b(
                                  {
                                    staticClass:
                                      "layout row wrap all-players-drag-area",
                                    attrs: {
                                      handle: ".handle",
                                      list: item.items,
                                      move: _vm.checkMove,
                                      disabled: _vm.teamDrag
                                    },
                                    on: {
                                      start: function($event) {
                                        _vm.playerDrag = true
                                      },
                                      end: function($event) {
                                        _vm.playerDrag = false
                                      },
                                      change: function($event) {
                                        return _vm.changeTeam($event, item.id)
                                      }
                                    }
                                  },
                                  "draggable",
                                  { group: "people", animation: 150 },
                                  false
                                ),
                                _vm._l(item.items, function(player, i) {
                                  return _c("PickTeamPlayer", {
                                    key:
                                      "no-team-player-" + i + "-" + player.id,
                                    staticClass: "pick-player",
                                    class: {
                                      handle:
                                        _vm.isHost || player.id == _vm.user.id
                                    },
                                    attrs: {
                                      "data-uuid": player.id,
                                      muted: player.muted,
                                      firstname: player.firstname,
                                      player: player,
                                      lastname: player.lastname,
                                      imageURL: player.image
                                    },
                                    on: {
                                      editThePlayer: function($event) {
                                        return _vm.editPlayer(_vm.subItem)
                                      }
                                    }
                                  })
                                }),
                                1
                              ),
                              _vm.isHost
                                ? [
                                    _vm.onlinePlayersArray.length > 0
                                      ? [
                                          !_vm.playersOffTeam.length
                                            ? _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { right: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "pick-team__randomize",
                                                                  attrs: {
                                                                    color:
                                                                      "secondary",
                                                                    absolute:
                                                                      "",
                                                                    fab: "",
                                                                    loading:
                                                                      _vm.shuffling
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.shufflePlayers
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "shuffle"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("Shuffle players")
                                                  ])
                                                ]
                                              )
                                            : _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { right: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "pick-team__randomize",
                                                                  attrs: {
                                                                    color:
                                                                      "primary",
                                                                    absolute:
                                                                      "",
                                                                    fab: "",
                                                                    loading:
                                                                      _vm.randomizing
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.randomizePlayers
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "shuffle"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("Randomize players")
                                                  ])
                                                ]
                                              )
                                        ]
                                      : _vm._e()
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-flex",
                { staticClass: "pick-team-right-col", attrs: { xs6: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { column: "", "fill-height": "" } },
                    [
                      _vm.allTeams.length > 0
                        ? _vm._l(_vm.allTeams, function(item) {
                            return _c(
                              "v-flex",
                              {
                                key: item.id,
                                staticClass: "pick-team-row",
                                attrs: { "no-action": "", "d-flex": "" },
                                model: {
                                  value: item.active,
                                  callback: function($$v) {
                                    _vm.$set(item, "active", $$v)
                                  },
                                  expression: "item.active"
                                }
                              },
                              [
                                _c(
                                  "draggable",
                                  _vm._b(
                                    {
                                      staticClass: "flex d-flex team-group",
                                      attrs: {
                                        list: item.items,
                                        handle: ".handle",
                                        disabled: _vm.playerDrag
                                      },
                                      on: {
                                        start: function($event) {
                                          _vm.teamDrag = true
                                        },
                                        end: function($event) {
                                          _vm.teamDrag = false
                                        },
                                        change: function($event) {
                                          return _vm.changeTeam($event, item.id)
                                        }
                                      }
                                    },
                                    "draggable",
                                    { group: "people", animation: 250 },
                                    false
                                  ),
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "team-name-decorated-wrap"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "team-name-decorated"
                                          },
                                          [
                                            _c("SvgTriangle", {
                                              staticClass: "team-shape-left"
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "team-name" },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { staticClass: "team-icon" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.icon) +
                                                        " "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "team-name-span"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.name) +
                                                        " "
                                                    )
                                                  ]
                                                ),
                                                _vm.isHost && item.id !== 0
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        staticClass:
                                                          "team-edit-icon",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.onEditTeam(
                                                              item
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " settings_applications "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            ),
                                            _c("SvgTriangle", {
                                              staticClass: "team-shape-right"
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    _c(
                                      "draggable",
                                      _vm._b(
                                        {
                                          staticClass:
                                            "layout row wrap players-drag-area align-center",
                                          attrs: {
                                            handle: ".handle",
                                            list: item.items,
                                            disabled: _vm.teamDrag
                                          },
                                          on: {
                                            start: function($event) {
                                              _vm.playerDrag = true
                                            },
                                            end: function($event) {
                                              _vm.playerDrag = false
                                            },
                                            change: function($event) {
                                              return _vm.changeTeam(
                                                $event,
                                                item.id
                                              )
                                            }
                                          }
                                        },
                                        "draggable",
                                        { group: "people", animation: 150 },
                                        false
                                      ),
                                      _vm._l(item.items, function(player, i) {
                                        return _c("PickTeamPlayer", {
                                          key:
                                            "team-player-" +
                                            i +
                                            "-" +
                                            player.id,
                                          staticClass: "pick-player",
                                          class: {
                                            small: item.items.length > 4,
                                            handle:
                                              _vm.isHost ||
                                              player.id == _vm.user.id
                                          },
                                          attrs: {
                                            "data-uuid": player.id,
                                            muted: player.muted,
                                            player: player,
                                            firstname: player.firstname,
                                            lastname: player.lastname,
                                            imageURL: player.image
                                          },
                                          on: {
                                            click: function($event) {
                                              if (!$event.altKey) {
                                                return null
                                              }
                                              return _vm.editPlayer(player)
                                            }
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          })
                        : [
                            _c(
                              "v-flex",
                              {
                                attrs: {
                                  "d-flex": "",
                                  "align-center": "",
                                  "justify-center": ""
                                }
                              },
                              [
                                _c(
                                  "h1",
                                  {
                                    staticStyle: {
                                      color: "rgba(255, 255, 255, 0.3)",
                                      "text-align": "center"
                                    }
                                  },
                                  [_vm._v(" NO TEAMS ")]
                                )
                              ]
                            )
                          ]
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._l(_vm.audio, function(user) {
            return _c("UserAudio", {
              key: "pick-team-user-audio-" + user.id,
              attrs: { track: user.audioTrack }
            })
          })
        ],
        2
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400px" },
          model: {
            value: _vm.editTeam,
            callback: function($$v) {
              _vm.editTeam = $$v
            },
            expression: "editTeam"
          }
        },
        [
          _c(
            "v-layout",
            { attrs: { "justify-center": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-toolbar",
                    {
                      attrs: {
                        color: _vm.$theme.get("--primary-accent-color"),
                        dark: "",
                        height: "50px"
                      }
                    },
                    [
                      _c("v-toolbar-title", [
                        _vm._v(_vm._s(_vm.teamModalTitle) + " ")
                      ]),
                      _c("v-spacer")
                    ],
                    1
                  ),
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-container",
                        { attrs: { fluid: "", "grid-list-md": "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                [
                                  _c(
                                    "form",
                                    {
                                      on: {
                                        submit: function($event) {
                                          $event.preventDefault()
                                          return _vm.onSignin($event)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-card-text",
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              name: "firstname",
                                              label: "Team Name"
                                            },
                                            model: {
                                              value: _vm.team.name,
                                              callback: function($$v) {
                                                _vm.$set(_vm.team, "name", $$v)
                                              },
                                              expression: "team.name"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-card-text",
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.teamIcons,
                                              label: "Team Icon",
                                              dense: ""
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "selection",
                                                fn: function(data) {
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass:
                                                          "team-icon-input selected"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(data.item) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "item",
                                                fn: function(data) {
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass:
                                                          "team-icon-input"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(data.item) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ]),
                                            model: {
                                              value: _vm.team.icon,
                                              callback: function($$v) {
                                                _vm.$set(_vm.team, "icon", $$v)
                                              },
                                              expression: "team.icon"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "pick-team-btn-cancel",
                                          on: {
                                            click: function($event) {
                                              _vm.editTeam = false
                                            }
                                          }
                                        },
                                        [_vm._v(" Cancel ")]
                                      ),
                                      _vm.teamAction === "edit"
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "pick-team-btn-dismiss",
                                              on: { click: _vm.dismissTeam }
                                            },
                                            [_vm._v(" Dismiss ")]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "pick-team-btn-save",
                                          on: { click: _vm.saveTeam }
                                        },
                                        [_vm._v(" Save ")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("SoundEffects")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }