<template>
  <v-container pa-0 fluid fill-height class="pick-team">
    <v-layout column fill-height>
      <v-layout align-center shrink justify-center row>
        <Header>
          <v-spacer />
          <v-flex class="sfx-volume-wrap">
            <QuickVolumeTester class="volume-slider" />
          </v-flex>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-flex class="logout-btn-wrap" pr-1 @click="pushToPreGame">
                <div class="logout-btn" v-on="on">GAMES</div>
              </v-flex>
            </template>
            <span>Return to the EXPO</span>
          </v-tooltip>
          <v-flex
            v-if="!isHost"
            pl-0
            class="logout-btn-wrap"
            @click="pushToLogout"
          >
            <div class="logout-btn">LOG OUT</div>
          </v-flex>
        </Header>
      </v-layout>
      <v-layout row grow>
        <v-flex xs6 class="pick-team-left-col">
          <v-layout column fill-height>
            <v-flex shrink class="top-left-cell">
              <v-layout row fill-height>
                <v-flex class="host-media-container">
                  <transition name="host-transition" mode="out-in">
                    <v-flex class="host-media-wrap">
                      <HostMedia />
                    </v-flex>
                  </transition>
                </v-flex>
                <v-flex class="pick-team-info">
                  <v-flex>
                    <h1 v-if="!game.pickTeamLogo">
                      <br />{{ game.externalName || game.name }} <br />
                      <span>hosted by</span>
                      {{ hostName }}
                    </h1>
                    <v-flex v-if="!!startTime && isStandard" class="started">
                      <span v-if="time > 0"> Starts at </span>
                      <span v-else> Started at </span>
                      <span class="actual-start-time">
                        {{ startTime }}
                      </span>
                    </v-flex>
                    <hr />
                    <p v-if="!game.pickTeamText" class="body-text">
                      Please drag your icon to one of the teams to the right!
                    </p>
                    <p v-else>
                      {{ game.pickTeamText }}
                    </p>
                    <p
                      v-if="game.pickTeamLogo"
                      class="body-text"
                      style="text-align: center"
                    >
                      <img
                        :style="game.pickTeamLogoStyle"
                        :src="game.pickTeamLogo"
                      />
                    </p>
                  </v-flex>
                  <div class="num-of-players-decorated-wrap">
                    <div class="num-of-players-decorated">
                      <SvgTriangle class="team-shape-left"></SvgTriangle>
                      <div class="num-of-players">
                        <span>{{ numOfPlayersText }}</span>
                      </div>
                      <SvgTriangle class="team-shape-right"></SvgTriangle>
                    </div>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex
              grow
              class="all-players"
              :style="backgroundStyle"
              :class="{ gameimage: game.image }"
            >
              <v-flex
                class="all-players-scroll"
                v-for="item in noTeam"
                no-action
                :key="item.id"
                v-model="item.active"
              >
                <draggable
                  class="layout row wrap all-players-drag-area"
                  handle=".handle"
                  :list="item.items"
                  v-bind="{ group: 'people', animation: 150 }"
                  :move="checkMove"
                  :disabled="teamDrag"
                  @start="playerDrag = true"
                  @end="playerDrag = false"
                  @change="changeTeam($event, item.id)"
                >
                  <PickTeamPlayer
                    v-for="(player, i) in item.items"
                    class="pick-player"
                    :class="{ handle: isHost || player.id == user.id }"
                    :key="`no-team-player-${i}-${player.id}`"
                    :data-uuid="player.id"
                    :muted="player.muted"
                    :firstname="player.firstname"
                    :player="player"
                    :lastname="player.lastname"
                    :imageURL="player.image"
                    @editThePlayer="editPlayer(subItem)"
                  />
                </draggable>
                <template v-if="isHost">
                  <template v-if="onlinePlayersArray.length > 0">
                    <v-tooltip right v-if="!playersOffTeam.length">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="secondary"
                          absolute
                          fab
                          :loading="shuffling"
                          class="pick-team__randomize"
                          @click="shufflePlayers"
                        >
                          <v-icon>shuffle</v-icon>
                        </v-btn>
                      </template>
                      <span>Shuffle players</span>
                    </v-tooltip>
                    <v-tooltip v-else right>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="primary"
                          absolute
                          fab
                          :loading="randomizing"
                          class="pick-team__randomize"
                          @click="randomizePlayers"
                        >
                          <v-icon>shuffle</v-icon>
                        </v-btn>
                      </template>
                      <span>Randomize players</span>
                    </v-tooltip>
                  </template>
                </template>
              </v-flex>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs6 class="pick-team-right-col">
          <v-layout column fill-height>
            <template v-if="allTeams.length > 0">
              <v-flex
                v-for="item in allTeams"
                v-model="item.active"
                no-action
                d-flex
                class="pick-team-row"
                :key="item.id"
              >
                <draggable
                  class="flex d-flex team-group"
                  :list="item.items"
                  handle=".handle"
                  v-bind="{ group: 'people', animation: 250 }"
                  :disabled="playerDrag"
                  @start="teamDrag = true"
                  @end="teamDrag = false"
                  @change="changeTeam($event, item.id)"
                >
                  <div class="team-name-decorated-wrap">
                    <div class="team-name-decorated">
                      <SvgTriangle class="team-shape-left" />
                      <div class="team-name">
                        <v-icon class="team-icon">
                          {{ item.icon }}
                        </v-icon>
                        <span class="team-name-span">
                          {{ item.name }}
                        </span>
                        <v-icon
                          class="team-edit-icon"
                          v-if="isHost && item.id !== 0"
                          @click="onEditTeam(item)"
                        >
                          settings_applications
                        </v-icon>
                      </div>
                      <SvgTriangle class="team-shape-right" />
                    </div>
                  </div>
                  <draggable
                    class="layout row wrap players-drag-area align-center"
                    handle=".handle"
                    :list="item.items"
                    v-bind="{ group: 'people', animation: 150 }"
                    :disabled="teamDrag"
                    @start="playerDrag = true"
                    @end="playerDrag = false"
                    @change="changeTeam($event, item.id)"
                  >
                    <PickTeamPlayer
                      v-for="(player, i) in item.items"
                      class="pick-player"
                      :class="{
                        small: item.items.length > 4,
                        handle: isHost || player.id == user.id
                      }"
                      :key="`team-player-${i}-${player.id}`"
                      :data-uuid="player.id"
                      :muted="player.muted"
                      :player="player"
                      :firstname="player.firstname"
                      :lastname="player.lastname"
                      :imageURL="player.image"
                      @click.alt="editPlayer(player)"
                    />
                  </draggable>
                </draggable>
              </v-flex>
            </template>
            <template v-else>
              <v-flex d-flex align-center justify-center>
                <h1 style="color: rgba(255, 255, 255, 0.3); text-align: center">
                  NO TEAMS
                </h1>
              </v-flex>
            </template>
          </v-layout>
        </v-flex>
      </v-layout>
      <UserAudio
        v-for="user in audio"
        :track="user.audioTrack"
        :key="`pick-team-user-audio-${user.id}`"
      />
    </v-layout>
    <!-- Team edit window -->
    <v-dialog v-model="editTeam" max-width="400px"
      ><v-layout justify-center>
        <v-flex xs12>
          <v-toolbar
            :color="$theme.get('--primary-accent-color')"
            dark
            height="50px"
          >
            <v-toolbar-title>{{ teamModalTitle }} </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card>
            <v-container fluid grid-list-md>
              <v-layout row wrap>
                <v-flex>
                  <form @submit.prevent="onSignin">
                    <v-card-text>
                      <v-text-field
                        v-model="team.name"
                        name="firstname"
                        label="Team Name"
                      />
                    </v-card-text>
                    <v-card-text>
                      <v-select
                        :items="teamIcons"
                        v-model="team.icon"
                        label="Team Icon"
                        dense
                      >
                        <template slot="selection" slot-scope="data">
                          <v-icon class="team-icon-input selected">
                            {{ data.item }}
                          </v-icon>
                        </template>
                        <template slot="item" slot-scope="data">
                          <v-icon class="team-icon-input">
                            {{ data.item }}
                          </v-icon>
                        </template>
                      </v-select>
                    </v-card-text>
                  </form>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      class="pick-team-btn-cancel"
                      @click="editTeam = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      v-if="teamAction === 'edit'"
                      class="pick-team-btn-dismiss"
                      @click="dismissTeam"
                    >
                      Dismiss
                    </v-btn>
                    <v-btn class="pick-team-btn-save" @click="saveTeam">
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>
        </v-flex>
      </v-layout>
    </v-dialog>
    <SoundEffects />
  </v-container>
</template>

<script>
import _ from "lodash"
import draggable from "vuedraggable"
import shuffle from "array-shuffle"
import Header from "@/components/GroupTeams/Common/Header"
import HostMedia from "@/components/GroupTeams/Common/HostMedia"
import PickTeamPlayer from "@/components/GroupTeams/Common/Player/PickTeamPlayer"
import { mapState, mapActions, mapGetters } from "vuex"
import SoundEffects from "@/components/GroupTeams/Common/SoundEffects"
import QuickVolumeTester from "@/components/GroupTeams/Misc/QuickVolumeTester"
import SvgTriangle from "@/components/Shared/SvgTriangle.vue"
import { Role, Time, Firebase, GameHistoryStorage } from "../../../helpers"
import { fetchUser } from "@/services/user.service"
import UserAudio from "@/components/GroupTeams/Common/User/UserAudio"

export default {
  name: "pickteam",
  components: {
    UserAudio,
    draggable,
    Header,
    HostMedia,
    PickTeamPlayer,
    SoundEffects,
    QuickVolumeTester,
    SvgTriangle
  },
  data() {
    return {
      teamDrag: false,
      playerDrag: false,
      player: {},
      editPlayerDialog: false,
      editTeam: false,
      teamAction: "Edit",
      team: {},
      teamID: 0,
      MAX_NUM_OF_TEAMS: 10,
      MIN_NUM_OF_TEAMS: 2,
      time: null,
      startTime: null,
      assignedHost: null,
      randomizing: false,
      shuffling: false
    }
  },
  async created() {
    if (this.game.hostUserID) {
      this.assignedHost = await fetchUser({ userID: this.game.hostUserID })
    }
    if (this.gameStarted && !this.hasPreGame && this.isHost) {
      await this.updateGameAny({
        theKey: this.gameID,
        started: false,
        endTimestamp: 0
      })
    }
    if (this.gameStartTime) {
      const { time, startTime } = Time.getCountdownTimes(
        this.gameStartTime,
        Date.now()
      )
      this.time = time
      this.startTime = startTime
    }
    GameHistoryStorage.add(this.$store.getters.game.theKey)
  },
  computed: {
    ...mapGetters("twilio", { twilioUsers: "users" }),
    ...mapGetters("auth", [
      "role",
      "user",
      "hasPreGame",
      "clientID",
      "client",
      "isHost",
      "isAudit",
      "isSpectator"
    ]),
    ...mapGetters("GameUsers", ["users"]),
    ...mapGetters(["gameID", "teamIcons", "game", "onlineUsersArray", "urlID"]),
    ...mapGetters({ teams: "chats" }),
    ...mapState("group", ["isUsersLocallyMuted"]),
    canShowGamesButton() {
      return (
        this.hasPreGame &&
        (this.isHost ||
          this.isAudit ||
          this.isSpectator ||
          (this.isFreeRoam && !this.game.tournamentID))
      )
    },
    isFreeRoam() {
      return this.client.restriction == 0 || this.client.restriction == 4
    },
    gameStarted() {
      return this.game ? !!this.game.started : false
    },
    gameStartTime() {
      return parseInt(this.game.startTimestamp) || 0
    },
    actualHost() {
      return this.onlineUsersArray.find(({ role }) => role === Role.Host)
    },
    host() {
      return this.actualHost || this.assignedHost
    },
    hostName() {
      return this.host ? this.host.firstname : ""
    },
    backgroundStyle() {
      if (this.game.image)
        return "background-image: url('" + this.game.image + "')"
      else return ""
    },
    isStandard() {
      return !this.game.gameType || this.game.gameType == "Standard"
    },
    audio() {
      const array = []
      for (let i = 0; i < this.onlineUsersArray.length; i++) {
        const user = this.onlineUsersArray[i]
        if (!user.muted && !this.isUsersLocallyMuted) {
          const audioTrack = this.twilioUsers?.[user.id]?.audioTrack
          if (audioTrack)
            array.push({
              id: user.id,
              audioTrack: audioTrack
            })
        }
      }
      return array
    },
    onlinePlayersArray() {
      return this.onlineUsersArray.filter(({ role }) => role === Role.Player)
    },
    nOfPlayersOnline() {
      return this.onlinePlayersArray.length
    },
    numOfPlayersText() {
      if (this.nOfPlayersOnline === 0) {
        return "No players"
      } else if (this.nOfPlayersOnline === 1) {
        return "1 Player"
      } else if (this.nOfPlayersOnline > 1) {
        return this.nOfPlayersOnline + " Players"
      } else {
        return ""
      }
    },
    allTeams() {
      return Object.entries(this.teams || {}).map(([key, value]) => ({
        icon: value.icon,
        name: value.name,
        id: key,
        items: this.playersOnTeam(key)
      }))
    },
    playersOffTeam() {
      return this.playersGroupedByTeam[0] || []
    },
    playersGroupedByTeam() {
      return this.onlinePlayersArray.reduce((acc, val) => {
        if (acc[val.teamID]) {
          acc[val.teamID].push(val)
        } else {
          acc[val.teamID] = [val]
        }
        return acc
      }, {})
    },
    noTeam() {
      return [
        {
          name: "NO TEAM",
          id: 0,
          items: this.playersOffTeam
        }
      ]
    },
    teamModalTitle() {
      if (this.teamAction === "edit") {
        if (this.team.name) {
          return `EDIT ${this.team.name}`
        } else {
          return "EDIT"
        }
      } else if (this.teamAction === "create") {
        return "CREATE NEW"
      } else {
        return ""
      }
    }
  },
  methods: {
    ...mapActions([
      "updateUserToTeamID",
      "deleteTeam",
      "updateTeam",
      "createTeam"
    ]),
    ...mapActions("Games", ["updateGameAny"]),
    pushToLogout() {
      this.$router.push("/logout")
    },
    async pushToGame() {
      await this.$router.push(`/game/${this.urlID}`)
    },
    async pushToPreGame() {
      await this.$router.push(`/reception/${this.clientID}`)
    },
    getRandomInt(min, max) {
      const _min = Math.ceil(min)
      const _max = Math.floor(max)
      return Math.floor(Math.random() * (_max - _min + 1)) + _min
    },
    async dismissTeam() {
      if (!this.team) return
      if (!this.team.id) return
      const { id: teamID, name } = this.team

      const response = confirm(`Team ${name} will be dismissed`)

      if (!response) return console.log("aborted by user")

      const playersToDismiss = this.playersOnTeam(teamID)

      const promises = playersToDismiss.map(user =>
        this.changeTeam({ added: { element: user } }, 0)
      )

      await Promise.all(promises)

      await this.deleteTeam({ id: teamID })

      this.editTeam = false
    },
    onEditTeam(team) {
      this.editTeam = true
      this.team = team
      this.teamAction = "edit"
    },
    onCreateTeam() {
      this.editTeam = true
      this.team = {}
      this.teamAction = "create"
    },
    updateTeamColor(value) {
      this.team.color = value.hex
    },
    updateTeamIcon(value) {
      this.team.icon = value
    },
    getRandomIcon() {
      if (this.teams) {
        const array = Object.values(this.teams)
        const pool = this.teamIcons.filter(
          string => !array.some(({ icon }) => icon === string)
        )
        return pool[this.getRandomInt(0, pool.length - 1)]
      } else {
        return this.teamIcons[this.getRandomInt(0, this.teamIcons.length - 1)]
      }
    },
    async saveTeam() {
      if (this.team.id) {
        await this.updateTeam(this.team)
      } else {
        const obj = {}
        obj.name = this.team.name
        obj.icon = this.team.icon || this.getRandomIcon()
        obj.show = true
        obj.muted = false
        obj.totalScore = 0
        obj.can_hear_facilitator = true
        obj.muted_facilitator = false
        obj.players = 0
        obj.active = true
        obj.slogan = "Manual"
        await this.createTeam(obj)
      }
      this.team = {}
      this.editTeam = false
    },
    async savePlayer() {
      this.editPlayerDialog = false
      await this.$store.dispatch("updateUser", {
        userID: this.player.id,
        obj: { ...this.player, correct: null }
      })
    },
    async deletePlayer() {
      this.editPlayerDialog = false
      await this.$store.dispatch("deletePlayer", { userID: this.player.id })
    },
    editPlayer(player) {
      if (!this.isHost) return
      this.player = player
      this.editPlayerDialog = true
    },
    async changeTeam($event, teamID) {
      if ($event.added) {
        try {
          const user = $event.added.element
          await this.updateUserToTeamID({ teamID, userID: user.id })
        } catch (e) {
          console.log(e.message)
        }
      }
    },
    playersOnTeam(teamID) {
      return this.playersGroupedByTeam[teamID] || []
    },
    checkMove(obj) {
      return (
        this.user.role !== Role.Player ||
        this.user.id === obj.dragged.dataset.uuid
      )
    },
    async randomizePlayers() {
      this.randomizing = true

      const teams = Firebase.normalizeSnapshotToArray(
        this.allTeams
      ).map(team => ({ id: team.id, count: team.items.length }))

      const users = []

      for (const user of this.playersOffTeam) {
        const team = _.minBy(teams, team => team.count)

        users.push({
          userID: user.id,
          collection: [{ key: "teamID", value: team.id }]
        })

        team.count++
      }

      await this.$store.dispatch("updateUsersInBulk", { array: users })

      this.randomizing = false
    },
    async shufflePlayers() {
      this.shuffling = true

      const teams = Firebase.normalizeSnapshotToArray(this.allTeams).map(
        team => team.id
      )

      const users = this.onlinePlayersArray
        .filter(({ teamID }) => teamID !== 0)
        .map(user => user.id)

      const shuffledUsersID = shuffle(users)

      const array = shuffledUsersID.map((userID, index) => ({
        userID,
        collection: [{ key: "teamID", value: teams[index % teams.length] }]
      }))

      await this.$store.dispatch("updateUsersInBulk", { array })

      this.shuffling = false
    }
  }
}
</script>

<style lang="scss">
.pick-team {
  .audio-element {
    pointer-events: none;
    position: fixed;
    bottom: 0;
    right: 0;
  }
  .pick-team-row {
    min-height: 160px;
  }
  .host-transition-enter-active,
  .host-transition-leave-active {
    transform: scale(1);
    opacity: 1;
  }
  .host-transition-enter-active {
    transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.7s !important;
  }
  .host-transition-leave-active {
    transition: all cubic-bezier(0.6, -0.28, 0.735, 0.045) 0.7s !important;
  }
  .host-transition-enter,
  .host-transition-leave-to {
    transform: scale(0.5);
    opacity: 0;
  }

  .pick-team-left-col {
    background-color: $color-tertiary-dark;
  }
  .pick-team-right-col {
    position: relative;
    background-color: $color-secondary-dark;
    border-left: 2px solid $primary_accent_color;
  }
  .top-left-cell {
    height: 42%;
    max-height: 350px;
    padding: 20px 6%;
    background-color: $color-primary-light;
    border-bottom: 2px solid $primary_accent_color;
    box-shadow: 0px 2px 2px rgba($color-black, 0.25);
  }
  .all-players {
    position: relative;
    background-image: url("../../../assets/honeycomb-texture.png");
    background-repeat: repeat;
    background-size: 35px;
    overflow: auto;
    &.gameimage {
      background-size: cover;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: rgba($color-black, 0.5);
      }
    }
  }
  .all-players-scroll {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .pick-team-info {
    position: relative;
    color: $color-primary-dark;
    padding: 0 0 6px 6%;
    min-width: 55%;
    hr {
      border-top: 1px solid $color-disabled;
      margin-bottom: 10px;
      margin-top: 7px;
    }
    h1 {
      color: $primary_accent_color;
      font-size: 1.8rem;
      line-height: 2.1rem;
      margin-top: -5px;
      span {
        font-weight: 400;
      }
    }
    .started {
      font-size: 12px;
      font-weight: bold;
      margin-top: 5px;
    }
    p {
      margin: 0;
      line-height: 18px;
    }
    .body-text {
      font-size: 1.1rem;
      line-height: 1.15rem;
    }
  }
  .host-media {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
  }
  .host-media-container {
    position: relative;
    height: 100%;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    min-width: 40%;
  }
  .host-media-wrap {
    position: absolute;
    width: 100%;
    margin-right: 0px;
    height: 100%;
  }
  .pick-player {
    flex-shrink: 1;
    flex-grow: 0;
    min-width: 90px !important;
    flex-basis: 16vmin !important;
    max-width: 16vmin !important;
    margin-right: 6px;
    margin-top: 0.5%;
    margin-bottom: 0.5%;
    color: $color-white;
    user-select: none;
  }
  .pick-player.handle {
    cursor: move;
  }
  .pick-player.small {
    flex-basis: 12.5vmin !important;
    max-width: 12.5vmin !important;
  }
  .team-name-decorated-wrap {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0.8em;
    margin-top: -1em;
    margin-top: calc(-1em + 0.75px);
  }
  .num-of-players-decorated-wrap {
    position: absolute;
    left: 0;
    width: 100%;
    top: 100%;
    text-align: center;
  }
  .num-of-players-decorated {
    display: inline-block;
    position: relative;
    top: 8px;
    z-index: 2;
    box-shadow: 0px 2px 2px rgba($color-black, 0.33);
    .team-shape-left,
    .team-shape-right {
      width: auto;
      height: 100%;
      position: absolute;
    }
    .team-shape-left {
      right: 100%;
      right: calc(100% - 1px);
    }
    .team-shape-right {
      left: 100%;
      left: calc(100% - 1px);
      transform: rotate(180deg);
    }
  }
  .num-of-players {
    position: relative;
    background-color: $primary_accent_color;
    color: $color-white;
    font-size: 1em;
    text-align: center;
    display: inline-block;
    span {
      display: inline-block;
      margin: 2px 3vw;
    }
  }

  .team-name-decorated {
    display: inline-block;
    position: relative;
    margin-left: 0.8em;
    box-shadow: 0px 2px 2px rgba($color-black, 0.1);
    z-index: 1;
    .team-shape-left,
    .team-shape-right {
      width: auto;
      height: 100%;
      position: absolute;
    }
    .team-shape-left {
      right: calc(100% - 1px);
    }
    .team-shape-right {
      left: calc(100% - 1px);
      transform: rotate(180deg);
    }
  }
  .team-name {
    position: relative;
    background-color: $primary_accent_color;
    color: $color-white;
    font-size: 1em;
    text-align: center;
    display: inline-block;
    .team-name-span {
      padding: 2px 6px;
    }
    .team-name-span,
    .team-icon,
    .team-edit-icon {
      position: relative;
      display: inline-block;
      vertical-align: middle;
    }
  }
  .team-icon {
    font-size: 1.5rem;
    margin-right: 0.1rem;
    color: $color-primary-dark;
    cursor: pointer;
  }
  .team-edit-icon {
    font-size: 1.5rem;
    margin-left: 0.1rem;
    color: inherit;
    cursor: pointer;
  }
  .team-group {
    position: relative;
    border-top: 2px solid $primary_accent_color;
    padding: 0.8em;
  }
  .go-to-game {
    height: 100%;
    text-align: right;
    font-size: 1em;
    font-weight: bold;
    padding: 0 10px;
    flex: initial;
    position: relative;
    z-index: 99;
    .btn {
      display: inline-block;
      color: $color-white;
      cursor: pointer;
      span {
        vertical-align: middle;
        display: inline-block;
        height: 28px;
        line-height: 29px;
      }
      span:last-child {
        padding-left: 2px;
        line-height: 26px;
      }
      .arrows {
        margin-left: 0.15em;
        margin-right: 0.15em;
        font-size: 1.8rem;
        color: $primary_accent_color;
      }
    }
    .btn:hover {
      .arrows {
        color: $secondary_accent_color;
      }
    }
  }
  .all-players-drag-area {
    margin: 20px 10px;
    min-height: 90px;
  }
  .players-drag-area {
    min-height: 90px;
  }
  .team-icon-input {
    font-size: 2.2em !important;
    color: $primary_accent_color !important;
  }
  .team-icon-input.selected {
    color: $color-correct !important;
  }

  .sfx-volume-wrap {
    position: relative;
    top: 2px;
    z-index: 99;
    flex: initial;
  }

  .logout-btn-wrap {
    display: flex;
    flex: initial;
    justify-content: flex-end;
    align-items: center;
    padding: 0 20px;
    .logout-btn {
      line-height: 1;
      position: relative;
      color: $color-white;
      font-size: 0.75rem;
      border-radius: 0.75rem;
      padding: 3px 12px;
      border: 1px solid $primary_accent_color;
      z-index: 99;
      user-select: none;
      cursor: pointer;
      &:hover {
        border: 1px solid $secondary_accent_color;
      }
    }
  }
}

.pick-team {
  &__randomize {
    left: 8px;
    bottom: 8px;
  }
}
</style>
